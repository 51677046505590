<template>
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5214 1.66663C8.87327 1.66663 7.2621 2.15537 5.89169 3.07105C4.52129 3.98672 3.45318 5.28821 2.82245 6.81093C2.19172 8.33365 2.02669 10.0092 2.34824 11.6257C2.66978 13.2422 3.46345 14.7271 4.62889 15.8925C5.79433 17.0579 7.27919 17.8516 8.89569 18.1732C10.5122 18.4947 12.1878 18.3297 13.7105 17.699C15.2332 17.0682 16.5347 16.0001 17.4504 14.6297C18.366 13.2593 18.8548 11.6481 18.8548 9.99996C18.8524 7.79056 17.9736 5.67236 16.4113 4.11008C14.849 2.5478 12.7308 1.66905 10.5214 1.66663ZM10.5214 14.1666C10.3566 14.1666 10.1955 14.1177 10.0585 14.0262C9.92143 13.9346 9.81462 13.8045 9.75155 13.6522C9.68847 13.4999 9.67197 13.3324 9.70413 13.1707C9.73628 13.0091 9.81565 12.8606 9.93219 12.744C10.0487 12.6275 10.1972 12.5481 10.3589 12.516C10.5205 12.4838 10.6881 12.5003 10.8403 12.5634C10.9926 12.6265 11.1228 12.7333 11.2143 12.8703C11.3059 13.0074 11.3548 13.1685 11.3548 13.3333C11.3548 13.5543 11.267 13.7663 11.1107 13.9225C10.9544 14.0788 10.7425 14.1666 10.5214 14.1666ZM11.3548 10.8333C11.3548 11.0543 11.267 11.2663 11.1107 11.4225C10.9544 11.5788 10.7425 11.6666 10.5214 11.6666C10.3004 11.6666 10.0885 11.5788 9.93219 11.4225C9.77591 11.2663 9.68811 11.0543 9.68811 10.8333V6.66662C9.68811 6.44561 9.77591 6.23365 9.93219 6.07737C10.0885 5.92109 10.3004 5.83329 10.5214 5.83329C10.7425 5.83329 10.9544 5.92109 11.1107 6.07737C11.267 6.23365 11.3548 6.44561 11.3548 6.66662V10.8333Z"
      fill="#E02424"
    />
  </svg>
</template>
